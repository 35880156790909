<template>
  <hs-button class="go-to-my-devices-button" @click="goToMyDevices" size="md"
    >{{ buttonText }}
  </hs-button>
</template>
<script>
export const GO_TEXT = 'Go To My Device List';
export const GO_BACK_TEXT = 'Back To Device List';

export default {
  props: {
    back: {
      default: false,
    },
  },
  methods: {
    goToMyDevices() {
      const {query} = this.$route;
      delete query.case;
      delete query.step;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
  },
  computed: {
    buttonText() {
      return this.back ? GO_BACK_TEXT : GO_TEXT;
    },
  },
};
</script>
