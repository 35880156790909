<template>
  <div>
    <ClaimSuccessLoading v-if="isLoading" />
    <StatusPage
      v-else
      class="carry-in-success"
      status="success"
      :title="title"
      restrict-title-width
    >
      <div>
        <p class="carry-in-success__text">
          Note: Before dropping off your device, please back it up and unlock it (or provide us with
          your access code/password).
        </p>
        <GoToMyDevicesButton class="carry-in-success__btn-link" :back="isGoBackToMyDevices" />
      </div>
    </StatusPage>
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions} from 'vuex';
import StatusPage from '@/components/shared/StatusPage';
import GoToMyDevicesButton from '@/components/shared/GoToMyDevicesButton';
import ClaimSuccessLoading from '@/components/shared/ClaimSuccessLoading';
import {allowDebug} from '@/constants/env';

export default {
  components: {
    StatusPage,
    GoToMyDevicesButton,
    ClaimSuccessLoading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState('jobInfo', ['claimsList', 'jobInfo']),
    seekingJob() {
      return this.claimsList
        ? this.claimsList.find((i) => i.claimsMasterId === this.$route.params.id)
        : null;
    },
    title() {
      return this.$route.query.case === 'reschedule'
        ? 'All set. You’ve successfully rescheduled your Service Call for Carry-In Service.'
        : "Good news! You've successfully set up your Service Call for Carry-In Service";
    },
    isGoBackToMyDevices() {
      return this.$route.query.case !== 'create';
    },
  },
  methods: {
    ...mapMutations('jobInfo', ['SET_JOB_INFO', 'CLEAR_STORE']),
    ...mapActions('requestService', ['START_SERVIFY_CLAIM', 'GET_LOCATION_BY_ADDRESS']),
    showClaimCreationError() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      const devicesLink = {
        name: 'MyDevices',
        query,
      };
      this.$store.dispatch('setModalError', {
        text: [
          'Seems like we have a trouble processing your request.',
          'Please contact the call center.',
        ],
        cta: [
          {
            text: 'Go to My Devices',
            callback: () => {
              this.$store.commit('errorModal/CLEAR_ERROR_MODAL_DATA');
              this.$router.push(devicesLink);
            },
          },
        ],
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.case) {
      next({
        name: to.name,
        params: {...to.params},
        query: {...to.query, case: from.name ? 'reschedule' : 'create'},
      });
    } else {
      next();
    }
  },
  async created() {
    if (this.seekingJob) {
      this.SET_JOB_INFO(this.seekingJob);
    } else {
      const {customerRef} = this.$route.query;
      this.$store
        .dispatch('user/DECRYPT_PARAMS', {
          customerRef,
        })
        .then((resp) => {
          this.$store
            .dispatch('jobInfo/GET_CLAIMS_LIST', {
              customerRef: resp?.customerRef,
            })
            .then(async () => {
              this.SET_JOB_INFO(this.seekingJob);
              const claimDetails = this.seekingJob?.claimDetails;
              const deviceId = claimDetails?.inventoryEquipmentID;
              const scheduledDate = this.seekingJob?.scheduledServiceDate?.split('T');
              const scheduledDateTime = scheduledDate[0];
              const scheduledFromTime = claimDetails?.scheduledFromTime;
              const scheduledToTime = claimDetails?.scheduledToTime;
              const serviceLocationCode = this.seekingJob?.serviceProviderAccount;
              const triageReferenceId = claimDetails?.triageReferenceID;
              const productSubCategoryCode = this.seekingJob?.productLine;
              const issueCode = this.seekingJob?.complaintCode;
              const issueRemarks = this.seekingJob?.complainCodeName;
              const issueCategoryCode = this.seekingJob?.issueCategoryCode;
              // TODO: Lost date should be less of equal to current date to be valid.
              // That might cause issues when client-side time is different from server-side timezone.
              const lossDateTime = claimDetails?.lossDateTime?.split('T')[0];
              const descriptionOfLoss = claimDetails?.descriptionOfLoss;
              const externalReferenceID = this.$route.params.id;

              const _addressType = this.seekingJob?.customerAddressType;
              const _city = this.seekingJob?.customerCity;
              const _flatNo = this.seekingJob?.customerFlatNo;
              const _houseNo = this.seekingJob?.customerHouseNo;
              const _landmark = this.seekingJob?.customerLandmark;
              const _state = this.seekingJob?.customerState;
              const _address = this.seekingJob?.customerClaimAddressLine1;
              const _street = this.seekingJob?.customerStreetName;
              const _zipCode = this.seekingJob?.customerPostalCode;

              const geoLocation = await this.GET_LOCATION_BY_ADDRESS({
                city: _city,
                flatNo: _flatNo,
                houseNo: _houseNo,
                landmark: _landmark,
                state: _state,
                address: _address,
                streetName: _street,
                zipcode: _zipCode,
              });

              const servifyClaim = await this.START_SERVIFY_CLAIM({
                serviceTypeCode: 'CLAIM_CARRYIN',
                deviceId,
                productSubCategoryCode,
                customerRef: resp?.customerRef,
                contractRef: resp?.contractRef,
                externalReferenceID,
                issueCode,
                issueRemarks,
                issueCategoryCode,
                lossDateTime,
                descriptionOfLoss,
                consumerServiceRequestParams: {
                  scheduledDateTime,
                  scheduledFromTime,
                  scheduledToTime,
                  serviceLocationCode,
                  externalReferenceID,
                  triageReferenceId,

                  addressType: _addressType,
                  city: _city,
                  flatNo: _flatNo,
                  houseNo: _houseNo,
                  landmark: _landmark,
                  state: _state,
                  streetName: _street,
                  StreetName: _street,
                  address: _address,
                  zipcode: _zipCode,

                  lat: geoLocation?.latitude,
                  lng: geoLocation?.longitude,
                  latitude: geoLocation?.latitude,
                  longitude: geoLocation?.longitude,
                },
              });

              if (servifyClaim?.errorDetails?.errorDetail?.errorDescription) {
                this.showClaimCreationError();
                if (allowDebug) {
                  this.$toasted.show(servifyClaim.errorDetails.errorDetail.errorDescription);
                }
              }

              this.isLoading = false;
            });
        });
    }
  },
};
</script>

<style scoped lang="scss">
.carry-in-success {
  &__text {
    max-width: 510px;
    font-weight: 700;
    font-size: 18px;
  }
  &__operating-hours {
    max-width: 100%;
  }
  &__btn-link {
    margin-top: 43px;
  }
}
</style>
